import type { NumberFormatOptions } from '@intlify/core-base';
import { AppCurrency } from '~/data/domain/currency';
import { DEFAULT_LOCALE } from '~/data/domain/constants';

const defaultOptions: NumberFormatOptions = {
  maximumFractionDigits: 0,
  notation: 'standard' as 'standard' | 'scientific' | 'engineering' | 'compact',
};

export function formatNumber(
  number: number,
  locale = DEFAULT_LOCALE,
  options: NumberFormatOptions = defaultOptions,
): string {
  const formatter = new Intl.NumberFormat(locale, {
    ...options,
  });

  return formatter.format(number);
}

export function formatPrice(
  number: number,
  locale = DEFAULT_LOCALE,
  currency = AppCurrency.USD,
  options: NumberFormatOptions = defaultOptions,
): string {
  if (currency === AppCurrency.Fantic) {
    // Use a known currency to determine the position of the currency symbol
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: AppCurrency.BTC, // Use a placeholder currency
      currencyDisplay: 'symbol',
      ...defaultOptions,
      ...options,
    });

    // Format the number with the placeholder currency
    const formattedNumberWithPlaceholder = formatter.format(number);

    // Replace the placeholder currency symbol with the custom currency symbol
    return formattedNumberWithPlaceholder.replace(AppCurrency.BTC, AppCurrency.Fantic);
  } else {
    // Standard formatting for ISO currencies
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
      currency,
      ...defaultOptions,
      ...options,
    });

    return formatter.format(number);
  }
}

interface PercentFormatOptions {
  fractionDigits?: number;
  showPercentSign?: boolean;
  showPlusMinusSign?: boolean;
  modular?: boolean;
}

export function formatPercentage(number: number, options?: PercentFormatOptions) {
  const formatOptions = {
    fractionDigits: 2,
    showPercentSign: true,
    modular: false,
    showPlusMinusSign: false,
    ...options,
  };

  const stringifiedNumber = +number.toFixed(formatOptions.fractionDigits);
  const modularValue = Math.abs(+stringifiedNumber);
  const formattedNumber = formatOptions.showPlusMinusSign
    ? `${number > 0 ? '+' : '-'}${modularValue}`
    : formatOptions.modular
      ? modularValue
      : stringifiedNumber;

  return formatOptions.showPercentSign ? `${formattedNumber}%` : formattedNumber.toString();
}
